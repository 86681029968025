<template>
    <el-container>
        <el-main class="tabsdf">
            <el-card shadow="never">
                <el-form ref="form" :model="form" label-width="90px">
                    <el-row :gutter="10">
                        <el-col :span="6" :xs="24">
                            <el-form-item label="userId"><el-input v-model="form.userId" clearable
                                    placeholder="请输入userId"></el-input></el-form-item>
                        </el-col>
                        <el-col :span="6" :xs="24">
                            <el-form-item label="预支付状态">
                                <el-select v-model="form.prepayStatus" clearable placeholder="请选择预支付状态">
                                    <el-option :key="0" label="未确认" :value="0" />
                                    <el-option :key="1" label="已成功" :value="1" />
                                    <el-option :key="-1" label="创建失败" :value="-1" />
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6" :xs="24">
                            <el-form-item label="支付状态">
                                <el-select v-model="form.payStatus" clearable placeholder="请选择支付状态">
                                    <el-option :key="0" label="未确认" :value="0" />
                                    <el-option :key="1" label="已成功" :value="1" />
                                    <el-option :key="-1" label="支付失败" :value="-1" />
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6" :xs="24">
                            <el-form-item label="支付渠道">
                                <el-select v-model="form.payChannel" clearable placeholder="请选择支付渠道">
                                    <el-option :key="0" label="微信支付" :value="0" />
                                    <el-option :key="1" label="支付宝支付" :value="1" />
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6" :xs="24">
                            <el-form-item label="流水号"><el-input v-model="form.serialNo" clearable
                                    placeholder="请输入流水号"></el-input></el-form-item>
                        </el-col>
                    </el-row>
                    <el-row class="top_l">
                        <el-col :span="24" :xs="24">
                            <el-button type="primary" @click="jouShare">搜索</el-button>
                            <el-button type="primary" @click="buttonShowHide(1)" v-if="isOpen == 0">开启支付</el-button>
                            <el-button type="danger" @click="buttonShowHide(0)" v-else>关闭支付</el-button>
                        </el-col>
                    </el-row>
                </el-form>
                <!-- table 表格 -->
                <el-table v-loading="serloading" :data="tableData" :header-cell-style="{ background: '#eef1f6' }"
                    style="width: 100%" border stripe>
                    <el-table-column prop="userId" label="userId" width="80" align="center"></el-table-column>
                    <el-table-column prop="money" label="金额" width="80"></el-table-column>
                    <el-table-column label="支付渠道">
                        <template v-slot="scope">
                            <span v-if="scope.row.payChannel == 0">微信支付</span>
                            <span v-else-if="scope.row.payChannel == 1">支付宝支付</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="预支付状态">
                        <template v-slot="scope">
                            <span v-if="scope.row.prepayStatus == 0">未确认</span>
                            <span v-else-if="scope.row.prepayStatus == 1">已成功</span>
                            <span v-else>创建失败</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="prepayTime" label="预支付时间"></el-table-column>
                    <el-table-column label="支付状态">
                        <template v-slot="scope">
                            <span v-if="scope.row.payStatus == 0">未确认</span>
                            <span v-else-if="scope.row.payStatus == 1">已成功</span>
                            <span v-else>支付失败</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="payTime" label="支付时间"></el-table-column>
                    <el-table-column prop="payComment" label="支付描述"></el-table-column>
                    <el-table-column prop="createTime" label="创建时间"></el-table-column>
                    <el-table-column prop="updateTime" label="更新时间"></el-table-column>
                    <el-table-column prop="serialNo" label="流水号"></el-table-column>
                    <el-table-column prop="agreementNo" label="商家扣款协议号"></el-table-column>
                    <template #empty>
                        <el-empty :description="emptyText" :image-size="100"></el-empty>
                    </template>
                </el-table>
                <!-- 分页 -->
                <div class="pagin">
                    <el-pagination :small="true" v-model:currentPage="currentPage" v-model:page-size="pageSize"
                        :page-sizes="[10, 20, 50, 100]" background layout="total, sizes, prev, pager, next, jumper"
                        :total="+totals" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
                </div>
            </el-card>
        </el-main>
    </el-container>
</template>

<script>
export default {
    name: 'paymentDetails',
    data() {
        return {
            fullscreenshow: false,
            emptyText: '暂无数据',
            currentPage: 1,
            pageSize: 10,
            tableData: [],
            totals: null,
            serloading: false,
            productId: '',
            isOpen: '',
            form: {
                userId: '',
                prepayStatus: '',
                payStatus: '',
                payChannel: '',
                serialNo: ''
            },
        };
    },
    created() {
        if (document.body.clientWidth < 768) {
            this.fullscreenshow = true;
        } else {
            this.fullscreenshow = false;
        }
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);

        // 二级目录ID
        this.productId = this.$TOOL.data.get('DATA_SELECTEED');

        // 获取产品信息
        this.paymentDetails();
        // 查询开关状态
        this.isOpenClick();
    },
    watch: {
        '$store.state.dataSelected'(newval) {
            if (this.productId != newval) {
                this.productId = newval;
                // 获取支付明细
                this.paymentDetails();
                // 查询开关状态
                this.isOpenClick();
            }
        },
    },
    methods: {
        // 获取支付明细
        paymentDetails() {
            this.serloading = true;
            this.$HTTP.post(
                'tradePayDetail/query',
                {
                    productId: this.productId,
                    pageNumber: this.currentPage,
                    pageSize: this.pageSize,
                    userId: this.form.userId,
                    prepayStatus: this.form.prepayStatus,
                    payStatus: this.form.payStatus,
                    payChannel: this.form.payChannel,
                    serialNo: this.form.serialNo,
                },
                response => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.data.code == 0) {
                            this.tableData = response.data.data.records;
                            this.totals = response.data.data.total;
                            this.serloading = false;
                        } else {
                            this.$message.error(response.data.msg);
                        }
                    } else {
                        console.log(response.message);
                    }
                }
            );
        },
        // 开启/关闭 提现
        buttonShowHide(e) {
            this.$confirm('此操作将更改支付, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$HTTP.post(
                    'tradePayDetail/updatePayIsOpen',
                    {
                        isOpen: e,
                        productId: this.productId,
                    },
                    response => {
                        if (response.status >= 200 && response.status < 300) {
                            if (response.data.code == 0) {
                                // 查询开关状态
                                this.isOpenClick();
                            } else {
                                this.$message.error(response.data.msg);
                            }
                        } else {
                            console.log(response.message);
                        }
                    }
                );
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消更改'
                });
            });


        },
        isOpenClick() {
            // 查询开关状态
            this.$HTTP.post('tradePayDetail/getPayIsOpen', {
                productId: this.productId,
            }, response => {
                if (response.status >= 200 && response.status < 300) {
                    if (response.data.code == 0) {
                        this.isOpen = response.data.data.isOpen;
                    } else {
                        this.$message.error(response.data.msg);
                    }
                } else {
                    console.log(response.message);
                }
            });
        },
        // 搜索
        jouShare() {
            this.currentPage = 1;
            // 获取支付明细
            this.paymentDetails();
        },
        // 分页
        handleSizeChange(val) {
            this.currentPage = 1;
            this.pageSize = val;
            // 获取支付明细
            this.paymentDetails();
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            // 获取支付明细
            this.paymentDetails();
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
